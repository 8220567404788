import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['nameInput', 'emailInput', 'ibanInput']
  static values = {
    clientSecret: String,
    redirectUrl: String,
    publishableKey: String
  }

  async connect (event) {
    this.stripe = await loadStripe(this.publishableKeyValue)

    this.mountStripeForm()
  }

  mountStripeForm () {
    const elements = this.stripe.elements({ clientSecret: this.clientSecretValue })

    this.ibanElement = elements.create('iban', {
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR'
    })

    this.ibanElement.mount(this.ibanInputTarget)
  }

  handleSubmit (event) {
    event.preventDefault()

    this.stripe.confirmSepaDebitPayment(
      this.clientSecretValue,
      {
        payment_method: {
          sepa_debit: this.ibanElement,
          billing_details: {
            name: this.nameInputTarget.value,
            email: this.emailInputTarget.value
          }
        }
      }
    ).then((result) => {
      if (result.paymentIntent) {
        const redirectUrl = new URL(this.redirectUrlValue)
        redirectUrl.searchParams.append('payment_intent_id', result.paymentIntent.id)
        window.location = redirectUrl
      }
    })
  }
}
