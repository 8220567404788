import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['elToFilter', 'emptyState']
  static values = {
    default: String
  }

  connect () {
    this.applyFilter(window.location.hash.slice(1) || this.defaultValue)
  }

  filterElements (event) {
    this.applyFilter(event.detail)
  }

  applyFilter (filter) {
    let visibleElCount = 0

    this.elToFilterTargets.forEach(elToFilter => {
      if (filter === 'all' || elToFilter.getAttribute('data-filter-value') === filter) {
        elToFilter.classList.remove('hidden')
        visibleElCount++
      } else {
        elToFilter.classList.add('hidden')
      }
    })

    this.toggleEmptyState(visibleElCount)
  }

  toggleEmptyState (visibleElCount) {
    const classList = this.emptyStateTarget.classList
    visibleElCount ? classList.add('hidden') : classList.remove('hidden')
  }
}
