import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    speed: Number
  }

  scrollRight (event) {
    clearInterval(this.scrollInterval)

    this.scrollInterval = setInterval(() => {
      event.target.scrollLeft += this.speedValue
    }, 30)
  }

  stopScrolling (event) {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval)
    }
    event.target.scrollLeft = 0
  }
}
