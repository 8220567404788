import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accordion']

  handleToggle (event) {
    this.accordionTargets.forEach(accordion => {
      if (!accordion.contains(event.target)) {
        accordion.open = false
      }
    })
  }
}
