import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = ['container', 'name', 'slug', 'error']
  static values = {
    apiUrl: String,
    errorMessage: String
  }
  static classes = ['failure']

  async preview () {
    if (this.nameTarget.value === '') {
      this.slugTarget.value = ''
      this.resetInputStyle()
    } else {
      const slug = this.sluggify(this.nameTarget.value)
      this.slugTarget.value = slug
      this.debouncedCheckSlugAvailability(slug)
    }
  }

  sluggify (text) {
    return text.toString().toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '')
  }

  debouncedCheckSlugAvailability = debounce(this.checkSlugAvailability, 300)

  async checkSlugAvailability (slug) {
    const response = await get(`${this.apiUrlValue}?club_name=${slug}`)

    if (response.ok) {
      const slugData = await response.json
      this.displayAvailabilityStatus(slugData.availability)
    } else {
      this.displayError('An error occurred while checking the slug availability.')
    }
  }

  displayAvailabilityStatus (available) {
    this.resetInputStyle()
    if (!available) {
      this.displayError()
    }
  }

  resetInputStyle () {
    this.containerTarget.classList.remove(this.failureClass)
    if (this.hasErrorTarget) {
      this.errorTarget.remove()
    }
  }

  displayError () {
    const message = `
      <div class="mb-2 text-red-200 text-sm pl-2" data-slug-preview-target="error">
        ${this.errorMessageValue}
      </div>
    `
    this.containerTarget.classList.add(this.failureClass)
    this.containerTarget.insertAdjacentHTML('beforeend', message)
  }
}
