import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['teamwearVendorInput']
  static values = { noVendorDisplay: String, noVendorString: String }

  connect () {
    if (this.teamwearVendorInputTargets.some((input) => input.value === this.noVendorStringValue)) {
      this.setBlankTeamwearVendor()
    }
  }

  setBlankTeamwearVendor () {
    this.teamwearVendorInputTargets.forEach((input) => {
      input.value = this.noVendorDisplayValue
    })
    this.resetInputValueBeforeSubmit = true
  }

  submit () {
    if (this.resetInputValueBeforeSubmit) {
      this.teamwearVendorInputTargets.forEach((input) => {
        input.value = this.noVendorStringValue
      })
    }

    this.element.submit()
  }
}
