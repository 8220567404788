import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['content']
  static values = {
    bundleName: String,
    clientSecret: String,
    codesList: Array,
    paymentAmount: Number,
    publishableKey: String,
    redirectUrl: String,
    voucherUnitary: Number
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue)

    this.mountStripeForm()
  }

  mountStripeForm () {
    this.elements = this.stripe.elements({ clientSecret: this.clientSecretValue })

    const paymentRequest = this.stripe.paymentRequest({
      country: 'FR',
      currency: 'eur',
      total: {
        label: `Lot de bons d’achat ${this.bundleNameValue}`,
        amount: this.paymentAmountValue
      },
      requestPayerName: true,
      displayItems: this.buildDisplayItems()
    })

    const prButton = this.elements.create('paymentRequestButton', { paymentRequest })

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        prButton.mount(this.contentTarget)
      } else {
        this.element.remove()
      }
    })

    paymentRequest.on('paymentmethod', async (event) => {
      const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
        this.clientSecretValue,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      )

      if (confirmError) {
        event.complete('fail')
      } else {
        event.complete('success')
        const redirectUrl = new URL(this.redirectUrlValue)
        redirectUrl.searchParams.append('payment_intent_id', paymentIntent.id)
        window.location = redirectUrl
      }
    })
  }

  buildDisplayItems () {
    return this.codesListValue.map((code) => {
      return {
        label: `Bon d’achat ${code}`,
        amount: this.voucherUnitaryValue
      }
    })
  }
}
