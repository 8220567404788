import { RedirectController } from '~/dashboard/controllers/redirect_controller.js'

export default class extends RedirectController {
  static targets = ['processingContent', 'successContent', 'failureContent']
  static values = {
    paymentIntentStatusUrl: String,
    redirectUrl: String
  }

  connect () {
    this.fetchAndHandlePaymentStatus()
  }

  fetchAndHandlePaymentStatus () {
    fetch(this.paymentIntentStatusUrlValue)
      .then((response) => response.json())
      .then(({ status }) => {
        if (status === 'succeeded') {
          this.displayMessageAndRedirect('success')
        } else if (['waiting', 'processing'].includes(status)) {
          setTimeout(this.fetchAndHandlePaymentStatus.bind(this), 1000)
        } else {
          this.displayMessageAndRedirect('failure')
        }
      })
  }

  displayMessageAndRedirect (status) {
    setTimeout(() => {
      this.processingContentTarget.classList.add('hidden')

      if (status === 'success') {
        this.successContentTarget.classList.remove('hidden', 'opacity-0')
      } else {
        this.failureContentTarget.classList.remove('hidden', 'opacity-0')
      }
    }, 3000)

    setTimeout(() => {
      this.redirectTo(this.redirectUrlValue)
    }, 5000)
  }
}
