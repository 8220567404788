import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleOrder (event) {
    const orderLine = event.currentTarget
    const attribute = orderLine.getAttribute('aria-expanded')

    if (attribute === 'false') {
      orderLine.setAttribute('aria-expanded', 'true')
    } else {
      orderLine.setAttribute('aria-expanded', 'false')
    }
  }
}
