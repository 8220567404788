// Import Rails libraries
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'

// Import Stimulus controllers
import '~/dashboard/controllers/index.js'

// Import common css
import '~/common/css/common.css'

// Import main css
import '~/dashboard/css/dashboard.css'

// Import utilities
import '~/common/javascript/user-tab.js'

ActiveStorage.start()
