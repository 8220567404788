import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['star']

  connect () {
    this.animateStars()
  }

  animateStars () {
    this.starTargets.forEach((star) => {
      this.resetStar(star)
      star.addEventListener('animationend', () => {
        this.resetStar(star)
        // Re-randomize after each reset
        this.animateStars()
      }, { once: true })
    })
  }

  resetStar (star) {
    const delay = Math.random() * 6
    // Random duration between 2 and 5 seconds
    const duration = 2 + Math.random() * 3
    star.style.animationDelay = `${delay}s`
    star.style.animationDuration = `${duration}s`

    // Random start position
    const startX = Math.random() * 100
    const startY = Math.random() * 100

    // Random end position
    const endX = startX + (Math.random() * 200 - 100)
    const endY = startY + (Math.random() * 200 - 100)

    star.style.left = `${startX}%`
    star.style.top = `${startY}%`
    star.style.setProperty('--endX', `${endX - startX}px`)
    star.style.setProperty('--endY', `${endY - startY}px`)
  }
}
