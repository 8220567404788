import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['file', 'fileReturn', 'preview', 'previewFile', 'previewText', 'upload']

  onDragEnter () {
    this.uploadTarget.classList.add('drag-over')
    this.uploadTarget.classList.remove('drop', 'done')
  }

  onDragLeave () {
    this.uploadTarget.classList.remove('drag-over')
  }

  onChange (event) {
    const file = this.fileTarget.value.split('\\')
    const fileName = file[file.length - 1]

    this.previewTextTarget.innerText = fileName
    this.uploadTarget.classList.replace('input-container', 'hidden')
    this.previewTarget.classList.remove('hidden')
  }

  removeFile () {
    this.uploadTarget.classList.remove('drag-over')
    this.previewTextTarget.innerText = ''
    this.uploadTarget.classList.replace('hidden', 'input-container')
    this.previewTarget.classList.add('hidden')
    this.fileTarget.value = ''
  }
}
