import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['brandInput', 'button', 'buttonContent', 'preview', 'submitButton']
  static values = { baseUrl: String }

  connect () {
    this.reset()
  }

  updatePreview () {
    this.updateButtonText()
    this.updateButtonHref()
    this.submitButtonTarget.disabled = false
    if (this.brandInputTargets.some(input => input.value === '')) {
      this.reset()
    }
  }

  updateButtonText () {
    const brandText = this.brandInputTargets.find(input => !input.classList.contains('hidden')).value

    if (brandText) {
      const templateText = this.buttonContentTarget.dataset.templateText

      this.buttonContentTarget.innerText = templateText.replace('%{brand}', brandText)
    }
  }

  updateButtonHref () {
    const brandId = this.brandInputTargets.find(input => input.classList.contains('hidden')).value

    if (brandId) {
      const url = new URL(this.baseUrlValue)
      url.searchParams.set('brand', brandId)

      this.buttonTarget.setAttribute('href', url.toString())
    }
  }

  reset (event) {
    if (!event || !this.element.contains(event.target)) {
      const turboFrame = document.querySelector('turbo-frame#preview')
      turboFrame.removeAttribute('src')
      turboFrame.innerHTML = ''
      this.submitButtonTarget.disabled = true
    }
  }

  togglePreview () {
    this.previewTarget.classList.toggle('open')
    this.element.setAttribute('open', true)
    const buttonText = this.previewTarget.classList.contains('open') ? 'Fermer la prévisualisation' : 'Prévisualiser la collection'
    this.buttonTarget.innerText = buttonText
  }

  hide (event) {
    if (!this.buttonTarget.contains(event.target) && !this.previewTarget.contains(event.target)) {
      this.element.removeAttribute('open')
      this.previewTarget.classList.remove('open')
      this.buttonTarget.innerText = 'Prévisualiser la collection'
    }
  }
}
