import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['cardNumber', 'cardExpiry', 'cardCvc', 'errorContainer', 'errorMessage']
  static values = {
    clientSecret: String,
    publishableKey: String,
    redirectUrl: String
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue)

    this.mountStripeForm()
  }

  mountStripeForm () {
    this.elements = this.stripe.elements({ clientSecret: this.clientSecretValue })

    this.mountCardNumberElement()
    this.mountCardExpiryElement()
    this.mountCardCvcElement()
  }

  mountCardNumberElement () {
    this.cardNumberElement = this.elements.create('cardNumber', { showIcon: true })
    this.cardNumberElement.mount(this.cardNumberTarget)
  }

  mountCardExpiryElement () {
    this.cardExpiryElement = this.elements.create('cardExpiry')
    this.cardExpiryElement.mount(this.cardExpiryTarget)
  }

  mountCardCvcElement () {
    this.cardCvcElement = this.elements.create('cardCvc')
    this.cardCvcElement.mount(this.cardCvcTarget)
  }

  async handleSubmit (event) {
    event.preventDefault()

    this.stripe.confirmCardPayment(
      this.clientSecretValue,
      { payment_method: { card: this.cardNumberElement } }
    ).then((result) => {
      if (result.error) {
        this.displayError(result.error)
      } else {
        const redirectUrl = new URL(this.redirectUrlValue)
        redirectUrl.searchParams.append('payment_intent_id', result.paymentIntent.id)
        window.location = redirectUrl
      }
    })
  }

  displayError (error) {
    this.errorContainerTarget.classList.remove('g-molecules--callout-hidden')
    this.errorMessageTarget.textContent = error.message
  }
}
