import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const config = {
      selector: '.js-headway',
      trigger: '.js-headway',
      account: import.meta.env.VITE_HEADWAY_ACCOUNT_ID,
      translations: {
        title: 'Nouveautés',
        readMore: 'En savoir plus',
        footer: 'Nouveautés Grinta'
      },
      callbacks: {
        onShowWidget: this.show.bind(this)
      }
    }
    window.Headway.init(config)
  }

  show () {
    this.element.dispatchEvent(new CustomEvent('headway:open'))
  }
}
