import { Controller } from '@hotwired/stimulus'
import { formatCurrency } from '~/common/javascript/utilities.js'

export default class extends Controller {
  static targets = ['nameField', 'valueField', 'quantityField', 'payButton', 'saveButton']

  connect () {
    this.checkForm()
    this.updatePrice()
    this.generateCodes()
  }

  checkForm () {
    if (!this.nameFieldTarget.value ||
      !this.valueFieldTarget.value ||
      !this.quantityFieldTarget.value) {
      this.payButtonTarget.setAttribute('disabled', 'disabled')
      this.saveButtonTarget.setAttribute('disabled', 'disabled')

      this.payButtonTarget.classList.add('cursor-not-allowed')
      this.saveButtonTarget.classList.add('cursor-not-allowed')
    } else {
      this.payButtonTarget.removeAttribute('disabled')
      this.saveButtonTarget.removeAttribute('disabled')

      this.payButtonTarget.classList.remove('cursor-not-allowed')
      this.saveButtonTarget.classList.remove('cursor-not-allowed')
    }
  }

  updatePrice () {
    if (this.valueFieldTarget.value > 0 && this.quantityFieldTarget.value > 0) {
      const valueInCents = this.valueFieldTarget.value * 100
      const price = valueInCents * this.quantityFieldTarget.value

      this.payButtonTarget.querySelector('.dash').classList.remove('hidden')
      this.payButtonTarget.querySelector('.price-holder').innerHTML = formatCurrency(price)
    } else {
      this.payButtonTarget.querySelector('.dash').classList.add('hidden')
      this.payButtonTarget.querySelector('.price-holder').innerHTML = ''
    }
  }
}
