import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['paymentMessagingElement']
  static values = {
    clientSecret: String,
    price: Number,
    publishableKey: String,
    redirectUrl: String,
    userEmail: String
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue, { locale: 'fr' })

    this.mountPaymentMethodMessagingElement()
  }

  mountPaymentMethodMessagingElement () {
    const elements = this.stripe.elements()
    const paymentMessageElement = elements.create(
      'paymentMethodMessaging',
      {
        amount: this.priceValue,
        currency: 'EUR',
        countryCode: 'FR',
        paymentMethodTypes: ['klarna']
      }
    )
    paymentMessageElement.mount(this.paymentMessagingElementTarget)
  }

  async handleSubmit (event) {
    event.preventDefault()

    this.stripe.confirmKlarnaPayment(
      this.clientSecretValue, {
        payment_method: {
          billing_details: {
            email: this.userEmailValue,
            address: { country: 'FR' }
          }
        },
        return_url: this.redirectUrlValue
      }
    )
  }
}
