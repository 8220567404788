import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['contentToEdit', 'inputToSync']
  static values = {
    for: String
  }

  toggleEditable (event) {
    const isChecked = event.target.checked
    isChecked ? this.addEditable() : this.removeEditable()
  }

  addEditable () {
    const target = this.contentToEditTarget
    target.innerHTML = this.inputToSyncTarget.value
    target.setAttribute('contenteditable', '')
    target.removeAttribute('for')
    target.focus()
  }

  removeEditable () {
    const target = this.contentToEditTarget
    target.innerHTML = target.getAttribute('data-i18n')
    target.removeAttribute('contenteditable')
    target.removeAttribute('style')
    target.setAttribute('for', this.forValue)
    this.inputToSyncTarget.value = ''
  }

  syncInput (event) {
    const content = event.target.innerHTML
    this.inputToSyncTarget.value = content
  }
}
