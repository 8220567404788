import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { key: String }

  connect () {
    if (this.dismissed) {
      this.element.remove()
    }
  }

  dismiss () {
    this.element.remove()
    window.localStorage.setItem(this.keyValue, 'dismissed')
  }

  get dismissed () {
    return window.localStorage.getItem(this.keyValue) === 'dismissed'
  }
}
