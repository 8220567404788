import { Controller } from '@hotwired/stimulus'
import { countBy } from 'lodash'

export default class extends Controller {
  static targets = ['teamwearFilter', 'selectionFilter', 'teamwearVariant', 'selectedVariant', 'placeholder', 'rightPanel', 'catalogue']
  static classes = ['activeFilter', 'animatePing']

  connect () {
    this.#updateFiltersCount()
    this.filter({ currentTarget: this.teamwearFilterTargets[0] })
  }

  filter (event) {
    this.#toggleActiveFilter(event)
    this.#filterVariants(event)
    this.#displayPlaceholder()
  }

  toggleRightPanel () {
    this.catalogueTarget.classList.toggle('frozen')
    this.rightPanelTarget.classList.toggle('active')
  }

  selectedVariantTargetConnected () {
    this.placeholderTarget.classList.add('hidden')
    this.#updateFiltersCount()
  }

  selectedVariantTargetDisconnected () {
    if (this.selectedVariantTargets.length === 0) {
      this.placeholderTarget.classList.remove('hidden')
    }
    this.#updateFiltersCount()
  }

  #toggleActiveFilter (event) {
    const allFilters = [...this.teamwearFilterTargets, ...this.selectionFilterTargets]

    for (const filter of allFilters) {
      if (filter.dataset.filter !== event.currentTarget.dataset.filter) {
        filter.classList.remove(this.activeFilterClass)
      } else {
        filter.classList.add(this.activeFilterClass)
      }
    }
  }

  #filterVariants (event) {
    const selectedFilter = event.currentTarget.dataset.filter
    const allVariants = [...this.teamwearVariantTargets, ...this.selectedVariantTargets]

    for (const variant of allVariants) {
      const adultFilterAndVariant = ['man', 'woman'].includes(selectedFilter) && variant.dataset.filter === 'adult'
      const filterLessVariant = variant.dataset.filter === undefined
      if (variant.dataset.filter === selectedFilter || adultFilterAndVariant || filterLessVariant) {
        variant.classList.remove('hidden')
      } else {
        variant.classList.add('hidden')
      }
    }
  }

  #displayPlaceholder () {
    const visibleSelectedVariants = this.selectedVariantTargets.filter(v => !v.classList.contains('hidden'))

    if (visibleSelectedVariants.length === 0) {
      this.placeholderTarget.classList.remove('hidden')
    } else {
      this.placeholderTarget.classList.add('hidden')
    }
  }

  #updateFiltersCount () {
    const filterCount = countBy(this.selectedVariantTargets, 'dataset.filter')
    const filterLessCount = this.selectedVariantTargets.filter(v => v.dataset.filter === undefined).length

    for (const filter of this.selectionFilterTargets) {
      const numberPill = filter.querySelector('.number-pill')
      const currentCount = parseInt(numberPill.textContent, 10)
      let newCount = filterCount[filter.dataset.filter] || 0
      newCount += filterLessCount

      if (currentCount !== newCount) {
        const elToAnimate = filter.querySelector('.animated')
        numberPill.textContent = newCount
        elToAnimate.classList.add(this.animatePingClass)

        setTimeout(() => {
          elToAnimate.classList.remove(this.animatePingClass)
        }, 1200)
      }
    }
  }
}
