import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default class extends Controller {
  static values = {
    placeholder: String,
    regex: String
  }

  connect () {
    /* eslint-disable-next-line no-new */
    this.tagifyInstance = new Tagify(this.element, {
      delimiters: ',|\n|\r',
      placeholder: this.placeholderValue,
      pattern: new RegExp(`^${this.regexValue}$`),
      transformTag: this.formatPaste.bind(this)
    })
  }

  formatPaste (tagData) {
    const emailMatch = tagData.value.match(/^<.*>\s*(\S+@\S+\.\S+)/)
    if (emailMatch) {
      tagData.value = emailMatch[1]
    } else {
      return tagData
    }
  }
}
